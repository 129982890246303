import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          {/* <span className="image main"><img src={pic01} alt="" /></span> */}
          <p>An engineer by heart, I love creating products. I have and always been an close observer of nature. Hence, I love to take concepts from nature while buidling software products.</p>
          <br/>
          <p>Apart from working on cutting edge technologies and solving problems everday. I love powerlifting and carpentry.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          {/* <span className="image main"><img src={pic02} alt="" /></span> */}
          Having worked across domains on multiple domains solving problems across the board. Systems I have worked are
          <ul>
            <li>
              Data Platforms
            </li>
            <li>
              Transformation Systems
            </li>
            <li>
              Payment Systems
            </li>
            <li>
              User Management
            </li>
            <li>
              File Management
            </li>
            <li>
              Event Driven Systems
            </li>
            <li>
              E commerce Platforms
            </li>
            <li>
              Search Engine Optimization
            </li>
            <li>
              and many more ....
            </li>
          </ul>
          <p>As these systems have varied requrements, the tech stack that I am exposed to is very vast. I will try to come up with a list (stay tuned)</p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main